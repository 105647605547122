<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data: info }">
      <PageTitle>Match</PageTitle>
      <div class="match">
        <div class="mb-4">
          <MatchHead
            :bookmark="hasBookmark"
            :date="info.created"
            :event="info.event"
            :map-id="info.map"
            :teams="info.teams"
            :vod-status="info.vod_status"
            @set:bookmark="setBookmark"
          />
        </div>

        <MatchRoundSelector
          :rounds="info.roundStats"
          :teams="info.teams"
          :scores="scores"
          class="justify-content-center mb-5"
        >
          <template #round="{ round }">
            <RoundItem
              :key="round.round_number"
              tag="router-link"
              :active="round.round_number === roundNum"
              :to="{ query: { round: round.round_number } }"
              :round="round"
              :teams="info.teams"
              :selected="true"
              @click.native="track_round(round.round_number + 1)"
            />
          </template>
        </MatchRoundSelector>

        <b-nav tabs>
          <b-nav-item
            :to="{ path: 'overview', query: $route.query }"
            active-class="active"
            @click="track_tab('Overview')"
          >
            Overview
          </b-nav-item>
          <b-nav-item
            :to="{ path: 'economy', query: $route.query }"
            active-class="active"
            @click="track_tab('Economy')"
          >
            Economy
          </b-nav-item>
          <b-nav-item
            :to="{ path: 'performance', query: $route.query }"
            active-class="active"
            @click="track_tab('Performance')"
          >
            K/D/A
          </b-nav-item>
          <b-nav-item
            v-if="false"
            :to="{ path: 'notes', query: $route.query }"
            active-class="active"
            @click="track_tab('Notes')"
          >
            Notes
          </b-nav-item>
          <li class="separator" />
          <b-nav-item v-if="false" :disabled="!mapPath" :to="mapPath" @click="trackMap"> 2D Map </b-nav-item>

          <b-nav-item v-if="false" @click.prevent="exportMatch"> Export </b-nav-item>
        </b-nav>

        <div>
          <router-view name="match" />
        </div>
      </div>
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'
import { mapActions, mapGetters } from 'vuex'

import { getMatchDetails, getSeriesDetails, getRiotLiveStatsMatchInfo } from '@/api/grid'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import PageTitle from '@/components/generic/PageTitle.vue'
import RoundItem from '@/components/Match.old/RoundItem.vue'
import MatchHead from '@/components/match/Head.vue'
import MatchRoundSelector from '@/components/match/MatchRoundSelector.vue'
import mixpanel from '@/mixpanel.js'
import gridApiKey from '@/pages/mixins/gridApiKey'
import downloadMatchesExport from '@/utils/downloadMatchesExport'
import { prepareGridMatchData } from '@/utils/prepareGridMatchData'

export default {
  name: 'GridMatchPage',
  mixins: [gridApiKey],
  components: {
    MatchRoundSelector,
    ApiLoadingController,
    MatchHead,
    PageTitle,
    RoundItem,
  },

  props: {
    roundNum: px.integer.def(-1),
    seriesId: px.string,
    gameId: px.string,
  },

  data: () => ({
    matchInfo: null,
    bookmarked: null,
  }),

  provide() {
    const matchData = {}
    Object.defineProperty(matchData, 'info', {
      get: () => this.matchInfo,
    })
    return { matchData }
  },

  computed: {
    ...mapGetters({
      getAgentById: 'static/getAgentById',
      agentsByNameLowercase: 'static/agentsByNameLowercase',
      mapsByAnything: 'static/mapsByAnything',
    }),
    params() {
      return {
        seriesId: this.seriesId,
        gameId: this.gameId,
        apiKey: this.apiKey,
      }
    },
    computedMatchInfo() {
      return this.matchInfo
    },

    hasBookmark() {
      return this.bookmarked
    },

    winnerTeam() {
      return this.matchInfo ? this.matchInfo.teams.find(team => team.win) : this.matchInfo.teams[0]
    },

    map() {
      return this.$store.getters['static/getMapById'](this.matchInfo.map)
    },

    mapPath() {
      if (!this.map || !this.matchInfo) return
      return {
        path: this.isCollegiate
          ? `/collegiate/map/${this.map.name}/${this.winnerTeam.team}`
          : `/map/${this.map.name}/${this.winnerTeam.team}`,
        query: { matches: [this.matchInfo.id] },
      }
    },

    sub_route() {
      return (this.$route.name || 'round').toLowerCase()
    },

    scores() {
      return this.matchInfo.teams.map(({ rounds_won }) => rounds_won)
    },

    isCollegiate() {
      return this.$route.params.isCollegiate || false
    },
  },
  methods: {
    ...mapActions({
      setBookmarkAction: 'bookmarks/set',
    }),

    exportMatch() {
      downloadMatchesExport([this.matchInfo.id], { trackOpts: { source: 'match stats' } })
    },

    async fetch({ seriesId, gameId, apiKey }) {
      const seriesDetails = await getSeriesDetails({ seriesId: seriesId }, { apiKey: apiKey || undefined })
      const seriesGamesDetails = await getMatchDetails({ seriesId: seriesId }, { apiKey: apiKey || undefined })
      const mapName = seriesGamesDetails.data.seriesState.games.find(game => game.id === gameId).map?.name
      const liveStatsInfo = await getRiotLiveStatsMatchInfo(
        { id: seriesId, map: this.mapsByAnything[mapName].name },
        { apiKey }
      )

      // TODO check bookmarked when supported for grid matches
      this.bookmarked = false

      this.matchInfo = prepareGridMatchData({
        series: seriesDetails?.data?.series,
        seriesState: seriesGamesDetails?.data?.seriesState,
        liveStatsInfo: liveStatsInfo,
        gameId: gameId,
        agents: this.agentsByNameLowercase,
        maps: this.mapsByAnything,
      })

      console.log('PARSED DATA', this.matchInfo)

      return this.matchInfo
    },

    setBookmark(value) {
      this.bookmarked = value
      return this.setBookmarkAction({ type: 'match', id: value ? this.matchInfo.id : this.matchInfo.bookmark, value })
    },

    trackMap($event) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, this.computedContext)
    },

    track_tab(tab) {
      mixpanel.track_match_tab(this.matchInfo.id, tab)
    },

    track_round(round) {
      mixpanel.track_match_rounds(this.matchInfo.id, round)
    },
  },
}
</script>

<style lang="scss" scoped>
.score {
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: $font-weight-bolder;
  color: $gray-300;
}

.separator {
  width: 1px;
  background: $gray-300;
}
</style>
