import * as Sentry from '@sentry/vue'

import calcTeamEco from '@/utils/calcTeamEco'
import { RIOT_OUTCOME } from '@/utils/gridMappings'

export const prepareGridMatchData = ({ series, seriesState, liveStatsInfo, gameId, agents, maps }) => {
  if (!series || !seriesState) {
    return null
  }

  const gameDetails = seriesState?.games.find(game => game.id === gameId)

  if (!gameDetails) {
    return null
  }

  const firstRoundSides = gameDetails?.segments
    ?.find(segment => segment.sequenceNumber === 1)
    ?.teams?.reduce((acc, team) => {
      acc[team.id] = team.side === 'attacker' ? 'Red' : 'Blue'
      return acc
    }, {})

  const matchData = {
    created: seriesState.startedAt || series.startTimeScheduled,
    map: maps[gameDetails.map?.name?.toLowerCase()].id,
    teams: gameDetails.teams.map(team => {
      const roundStats = gameDetails.segments.reduce(
        (acc, segment) => {
          const won = segment.teams.find(t => t.id === team.id).won
          return {
            rounds_won: acc.rounds_won + (won ? 1 : 0),
            rounds_first_half_won: acc.rounds_first_half_won + (won && segment.sequenceNumber <= 12 ? 1 : 0),
            rounds_second_half_won:
              acc.rounds_second_half_won + (won && segment.sequenceNumber > 12 && segment.sequenceNumber <= 24 ? 1 : 0),
            rounds_overtime_won: acc.rounds_overtime_won + (won && segment.sequenceNumber > 24 ? 1 : 0),
          }
        },
        { rounds_won: 0, rounds_first_half_won: 0, rounds_second_half_won: 0, rounds_overtime_won: 0 }
      )

      const teamPlayers = calcTeamPlayers(team, liveStatsInfo, agents, firstRoundSides)

      return {
        name: team.name,
        ...roundStats,
        is_self_team: gameDetails.teams[0].id === team.id,
        id: team.id,
        liveStatsId: firstRoundSides[team.id] === 'Red' ? liveStatsInfo.redTeamId : liveStatsInfo.blueTeamId,
        win: team.won,
        team_side: firstRoundSides[team.id],
        logo_url: series.teams?.find(listTeam => listTeam.baseInfo?.id === team.id)?.baseInfo?.logoUrl,
        players: teamPlayers,
        agent_ids: team.players
          .filter(player => player.character)
          .map(player => player.character.name.toLowerCase())
          .filter((name, index, array) => array.indexOf(name) === index)
          .map(character => {
            return agents[character]?.id
          }),
      }
    }),
  }

  matchData.roundStats = calcRoundStats(matchData, gameDetails, liveStatsInfo)
  matchData.economy = calcEconomy(matchData)

  // matchData.teams.sort(team => (team.win ? -1 : 1))

  // matchData.vod_status = matchInfo.replay_cropper_status

  // matchData.teams.forEach(team => {
  //   team.players = []
  //   matchData.participants_through.forEach(participant => {
  //     if (participant.team === team.id) {
  //       team.players.push({
  //         ...participant,
  //         ...riotData?.players.find(player => player.puuid === participant.puuid),
  //         id: participant.player,
  //         agent_name: agents[participant.agent].name,
  //         agent_icon_url: agents[participant.agent].display_icon_url,
  //       })
  //     }
  //   })
  // })
  // matchData.roundStats = calcRoundStats(matchData)
  // matchData.economy = calcEconomy(matchData)
  // matchData.rounds = riotData?.rounds?.map(roundData => {
  //   return { ...roundData, teams: matchData.roundStats.find(round => round.round_number === roundData.round_num).teams }
  // })

  return matchData
}

const calcRoundStats = (matchData, gameData, liveStatsInfo) => {
  const roundsSummary = []
  let wins_blue = 0
  let wins_red = 0
  let order = null

  gameData?.segments?.forEach(segment => {
    const firstHalf = segment.sequenceNumber <= 12
    order = segment.sequenceNumber

    if (matchData.teams.find(team => team.id === segment.teams.find(segTeam => segTeam.won).id).grid === 'Blue') {
      wins_blue++
    } else {
      wins_red++
    }

    const liveStatsRoundInfo = liveStatsInfo.matchRounds.find(
      roundInfo => roundInfo.roundNumber === segment.sequenceNumber
    )

    roundsSummary.push({
      first_half: firstHalf,
      round_number: segment.sequenceNumber - 1,
      order,
      teams: matchData.teams.map(team => {
        const liveStatsLoadout = liveStatsRoundInfo?.endBuyPhaseLoadout?.find(
          loadout => loadout.teamId === team.liveStatsId
        )
        const name = team.name
        const grid = team.team_side
        const roundTeam = segment.teams.find(roundTeam => roundTeam.id === team.id)
        const role = roundTeam.side === 'attacker' ? 'atk' : 'def'
        const kills = 0
        const won = roundTeam.won
        const won_by = won
          ? RIOT_OUTCOME[
              liveStatsInfo.generalRounds.find(
                generalRounfInfo => generalRounfInfo.roundNumber === segment.sequenceNumber
              ).winCondition
            ]
          : undefined
        const score = team.team_side === 'Blue' ? wins_blue : wins_red
        const loadout = liveStatsLoadout?.inventoryValue
        const plant = null
        const remaining = liveStatsLoadout?.money
        const pistol_won = won && (segment.sequenceNumber === 1 || segment.sequenceNumber === 13)
        const eco = calcTeamEco(loadout, segment.sequenceNumber - 1)
        return {
          id: team.id,
          name,
          grid,
          role,
          kills,
          won,
          won_by,
          score,
          loadout,
          eco,
          plant,
          remaining,
          pistol_won,
        }
      }),
    })
  })
  return roundsSummary
}

const calcTeamPlayers = (team, liveStatsInfo, agents, teamSides) => {
  const playerStats = liveStatsInfo.players.reduce((acc, player) => {
    acc[player.id] = {
      id: player.id,
      team_side: player.side,
      agent_id: agents[player.agent.toLowerCase()].id,
      kills: 0,
      deaths: 0,
      assists: 0,
      first_kills: 0,
      first_deaths: 0,
      avr_round_damage: null,
      avr_round_score: null,
      total_headshots: 0,
      total_shots: 0,
    }
    return acc
  }, {})

  liveStatsInfo.matchRounds.forEach(round => {
    const sortedKills = round.kills.filter(kill => kill.type === 'kill').sort((a, b) => a.tick - b.tick)
    sortedKills.forEach((kill, index) => {
      if (index === 0) {
        playerStats[kill.killerId].first_kills++
        playerStats[kill.victimId].first_deaths++
      }

      playerStats[kill.killerId].kills++
      playerStats[kill.victimId].deaths++
      kill.assistants?.forEach(assistant => {
        playerStats[assistant.assistantId].assists++
      })
    })
  })

  const players = team.players.map(player => {
    const agent = agents[player.character.name.toLowerCase()]
    const liveStatsId = liveStatsInfo.players.find(
      liveStatsPlayer =>
        liveStatsPlayer.side === teamSides[team.id] &&
        player.character.name.toLowerCase() === liveStatsPlayer.agent.toLowerCase()
    ).id

    return {
      agent: agent.id,
      agent_id: agent.id,
      agent_icon_url: agent.display_icon_url,
      agent_name: agent.name,
      game_name: player.name,
      ...playerStats[liveStatsId],
    }
  })
  // liveStatsInfo.matchRounds.flatMap(round => round.kills).forEach(kill => {

  // })
  return players
}

const calcEconomy = matchData => {
  const economy = {
    economyPreview: [],
    economyFirstHalf: [],
    economySecondHalf: [],
  }
  matchData.teams.forEach(team => {
    let firstHalfRole = null
    let seconHalfRole = null
    let firstHalfRounds = []
    let secondHalfRounds = []

    const ecoTeam = {
      id: team.id,
      team_name: team.name,
      icon_url: team.logo_url,
      pistol_won: 0,
      eco: {
        played: 0,
        won: 0,
      },
      semi_eco: {
        played: 0,
        won: 0,
      },
      semi_buy: {
        played: 0,
        won: 0,
      },
      full_buy: {
        played: 0,
        won: 0,
      },
    }

    matchData.roundStats.forEach(roundStat => {
      const teamStats = roundStat.teams.find(statsTeam => statsTeam.id === team.id)
      if (firstHalfRole === null && roundStat.first_half) {
        firstHalfRole = teamStats.role
      }
      if (seconHalfRole === null && !roundStat.first_half && roundStat.round_number < 24) {
        seconHalfRole = teamStats.role
      }

      const economyRoundStats = {
        bank: teamStats.remaining,
        eco: teamStats.eco,
        loadout: teamStats.loadout,
        outcome: teamStats.won_by,
        pistol_won: teamStats.pistol_won,
        win: teamStats.won,
      }

      if (roundStat.first_half) {
        firstHalfRounds.push(economyRoundStats)
      } else {
        secondHalfRounds.push(economyRoundStats)
      }

      switch (teamStats.eco) {
        case 'P':
          if (teamStats.won) {
            ecoTeam.pistol_won += 1
          }
          break
        case '$':
          ecoTeam.eco.played += 1
          if (teamStats.won) {
            ecoTeam.eco.won += 1
          }
          break
        case '$$':
          ecoTeam.semi_eco.played += 1
          if (teamStats.won) {
            ecoTeam.semi_eco.won += 1
          }
          break

        case '$$$':
          ecoTeam.semi_buy.played += 1
          if (teamStats.won) {
            ecoTeam.semi_buy.won += 1
          }
          break

        case '$$$$':
          ecoTeam.full_buy.played += 1
          if (teamStats.won) {
            ecoTeam.full_buy.won += 1
          }
          break
        default:
          console.error(`Unhandled round eco ${teamStats.eco}`)
          Sentry.captureException(new Error(`Unhandled round eco ${teamStats.eco}`))
          break
      }
    })
    economy.economyPreview.push(ecoTeam)
    economy.economyFirstHalf.push({
      icon_url: ecoTeam.icon_url,
      id: ecoTeam.id,
      name: ecoTeam.team_name,
      role: firstHalfRole,
      rounds: firstHalfRounds,
    })
    economy.economySecondHalf.push({
      icon_url: ecoTeam.icon_url,
      id: ecoTeam.id,
      name: ecoTeam.team_name,
      role: seconHalfRole,
      rounds: secondHalfRounds,
    })
  })
  return economy
}
