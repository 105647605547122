import px from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  props: {
    manualKey: px.string.def(null),
  },
  computed: {
    ...mapGetters({
      gridKey: 'auth/grid_key',
    }),
    apiKey() {
      return this.manualKey || this.gridKey
    },
  },
  methods: {
    updateInitialState(initialState) {
      const newl = new URL(location)
      newl.searchParams.set('state', initialState)
      history.replaceState(null, null, newl.toString())
    },
  },
}
